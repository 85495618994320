import * as _iconvLite2 from "iconv-lite";

var _iconvLite = "default" in _iconvLite2 ? _iconvLite2.default : _iconvLite2;

import _supportedNames from "./supported-names.json";
import _labelsToNames from "./labels-to-names.json";
var exports = {};
const iconvLite = _iconvLite;
const supportedNames = _supportedNames;
const labelsToNames = _labelsToNames;
const supportedNamesSet = new Set(supportedNames); // https://encoding.spec.whatwg.org/#concept-encoding-get

exports.labelToName = label => {
  label = String(label).trim().toLowerCase();
  return labelsToNames[label] || null;
}; // https://encoding.spec.whatwg.org/#decode


exports.decode = (uint8Array, fallbackEncodingName) => {
  let encoding = fallbackEncodingName;

  if (!exports.isSupported(encoding)) {
    throw new RangeError(`"${encoding}" is not a supported encoding name`);
  }

  const bomEncoding = exports.getBOMEncoding(uint8Array);

  if (bomEncoding !== null) {
    encoding = bomEncoding;
  } // iconv-lite will strip BOMs for us, so no need to do the stuff the spec does


  return iconvLite.decode(uint8Array, encoding);
}; // https://github.com/whatwg/html/issues/1910#issuecomment-254017369


exports.getBOMEncoding = uint8Array => {
  if (uint8Array[0] === 254 && uint8Array[1] === 255) {
    return "UTF-16BE";
  } else if (uint8Array[0] === 255 && uint8Array[1] === 254) {
    return "UTF-16LE";
  } else if (uint8Array[0] === 239 && uint8Array[1] === 187 && uint8Array[2] === 191) {
    return "UTF-8";
  }

  return null;
};

exports.isSupported = name => {
  return supportedNamesSet.has(String(name));
};

export default exports;
export const labelToName = exports.labelToName,
      decode = exports.decode,
      getBOMEncoding = exports.getBOMEncoding,
      isSupported = exports.isSupported;